import React from 'react';

export const ShoePlaceholderSVG = () => {
  return (
    <svg width="118" height="52" viewBox="0 0 118 52" fill="none">
      <g id="No shoe image" opacity="0.1">
        <path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M5.02862 2.19369C5.44823 3.78632 5.58809 4.83485 5.44822 5.33913C5.30835 5.84374 4.78044 8.78653 3.86447 14.168C3.50158 14.8824 3.19333 16.0442 2.93986 17.6534C2.55967 20.0672 0.824518 37.2356 1.27264 38.5411C1.57129 39.4115 1.91688 39.9733 2.30893 40.2262C1.61807 41.0826 1.27264 42.1123 1.27264 43.3152C1.27264 45.1198 0.946123 48.0908 1.27264 48.8722C1.59917 49.6537 2.70803 50.6197 3.28626 50.571C3.67174 50.5383 25.9409 50.6813 70.0939 51C81.3109 50.4995 88.6874 50.0608 92.2236 49.6843C95.607 49.324 102.415 47.6127 103.462 47.2586C107.494 45.8943 116.4 41.9113 116.812 40.2262C117.223 38.5411 116.933 37.4375 116.21 36.495C115.486 35.5528 114.505 31.5385 108.273 29.9394C102.04 28.3404 98.5915 28.9913 89.4784 26.5146C80.3651 24.0381 77.7574 21.6716 72.914 18.3802C68.0705 15.0886 59.0637 8.39747 54.2444 5.00809C51.0314 2.74867 49.1215 1.44791 48.5147 1.10599C46.6102 0.841739 44.9643 1.07218 43.5768 1.79732C41.4955 2.88502 34.1471 7.62716 31.6384 8.39747C29.1297 9.16778 21.1876 9.68734 20.7414 9.54075C20.2952 9.39416 13.3075 6.49623 11.0965 5.00809C8.88552 3.52028 6.85396 1.84233 6.15109 1.79732C5.68262 1.76725 5.30836 1.89938 5.02862 2.19369Z" stroke="black"/>
        <path id="Stroke 3" d="M23.7538 9.43969C23.3 9.43969 18.6573 8.45323 14.802 6.57653C10.9465 4.69983 8.63826 2.32746 8.03937 2.25985C7.44048 2.19225 6.94461 1.03775 5.85561 1.39252C5.12967 1.62914 4.86515 1.91825 5.06222 2.25985" stroke="black"/>
        <path id="Stroke 5" d="M115.718 41.46C115.198 38.7425 114.605 37.1063 113.941 36.5513C112.945 35.7189 111.456 32.8611 109.118 32.2746C106.781 31.6879 96.3257 32.8818 93.1756 31.3899" stroke="black"/>
        <path id="Stroke 7" d="M112.514 43.3246L112.23 39.37L111.909 38.0621L110.906 37.4263C109.235 34.7808 108.204 33.3414 107.814 33.1085C107.424 32.8755 107.902 32.5735 109.249 32.2025" stroke="black"/>
        <path id="Stroke 9" d="M115.558 35.4529C108.279 38.2603 102.75 39.9044 98.9723 40.385C93.3059 41.1057 88.9003 42.6612 79.5479 42.798C70.1956 42.9348 59.1845 42.5245 57.3942 42.6612C55.604 42.798 44.0736 40.9158 42.1417 40.7955C40.2097 40.6752 30.2868 41.4703 28.3845 41.8195C26.4823 42.1686 18.7481 42.1452 14.7877 41.4703C10.8273 40.7955 2.92602 39.9283 2.22379 40.1138" stroke="black"/>
        <path id="Stroke 11" d="M105.423 29.4399C104.069 30.9208 103.68 32.8787 104.256 35.3137C104.832 37.7484 105.298 39.0715 105.655 39.2824C106.43 43.2779 106.847 45.5377 106.906 46.0613" stroke="black"/>
        <path id="Stroke 13" d="M82.0726 23.9146C79.7915 24.8345 78.2181 25.8169 77.3528 26.8617C76.0547 28.4287 75.6255 28.057 75.161 29.8859C74.6967 31.7149 73.7614 47.7916 73.7896 50.8318" stroke="black"/>
        <path id="Stroke 15" d="M113.155 32.3652C107.31 35.4928 101.258 36.7835 94.9969 36.2378" stroke="black"/>
        <path id="Stroke 17" d="M73.1006 18.5514C69.9143 19.6742 67.7298 21.0289 66.5466 22.6158C64.7719 24.996 63.5241 30.0365 62.2404 31.8945C60.9568 33.7527 56.9461 40.2773 56.8547 42.4389C56.7632 44.6005 56.6719 50.6471 56.7632 50.9138" stroke="black"/>
        <path id="Stroke 19" d="M68.2941 15.3011C64.9734 16.5388 62.8781 17.8602 62.0083 19.265C60.7033 21.372 47.6781 39.5429 47.4992 41.1167C47.3202 42.6903 46.8163 50.2514 46.9403 50.8953" stroke="black"/>
        <path id="Stroke 21" d="M77.1061 21.3143C73.9363 21.9225 71.7769 23.1012 70.6279 24.8503C68.9044 27.4739 67.2961 34.4742 66.686 36.8254C66.0758 39.1768 65.2068 42.0401 65.076 42.8184C64.9453 43.5967 65.0071 50.1665 65.2068 50.9264" stroke="black"/>
        <path id="Stroke 23" d="M40.839 3.22632C46.7418 12.46 49.845 17.3858 50.1488 18.0036C50.6044 18.9305 51.5026 20.1451 51.9873 20.6715C52.4719 21.1977 59.5742 24.086 60.6837 24.9571C61.7934 25.828 66.175 28.4528 66.7594 28.92C67.3441 29.3872 75.4889 32.924 78.4716 33.4582C80.4601 33.8144 81.846 33.8144 82.6294 33.4582H94.73C94.1308 32.2699 93.5168 31.4854 92.888 31.1047C91.9449 30.5336 81.8208 28.92 78.9198 27.7005C76.0185 26.481 69.8095 22.3036 67.9591 20.6715C66.109 19.0393 56.4769 9.6152 55.8738 8.86098C55.2708 8.10675 51.4618 3.29717 50.1488 2.14495C49.2735 1.37675 48.2792 0.995171 47.1659 1.00005C49.1026 4.10112 50.7097 6.39857 51.9873 7.89223C53.9036 10.1328 62.7659 18.8356 64.493 20.3013C66.22 21.7671 71.9074 26.3355 73.6911 27.268C75.4748 28.2007 80.6173 29.7153 81.8208 31.1047C83.0244 32.494 83.6462 39.5097 83.0809 42.6117" stroke="black"/>
        <path id="Stroke 25" d="M107.707 33.0284L96.5153 33.4251H94.6927C94.8263 33.6393 94.921 34.0275 94.9769 34.5903C95.0328 35.1531 95.0328 35.7199 94.9769 36.2907H96.8644H98.6973V38.5618C98.501 32.0843 98.8631 28.7187 99.7837 28.4648" stroke="black"/>
        <path id="Stroke 27" d="M115.879 36.2654C112.807 37.6029 110.817 38.4312 109.908 38.75C108.545 39.2283 100.179 41.2264 98.9793 41.1998C98.18 41.1821 96.6405 41.4127 94.3609 41.8918L89.0005 42.7339L81.0504 43.6362L64.9601 44.1133L56.7278 43.6362L47.1549 42.7339C44.1013 42.1726 41.8073 41.8918 40.2729 41.8918H36.275L26.7769 43.1243H19.2676L12.7742 42.3343L8.06303 41.8918L3.90972 41.1998H1.57247" stroke="black"/>
        <path id="Stroke 29" d="M116.519 37.078L111.96 39.628L106.24 41.7979L99.5102 43.5516L84.8209 45.8614L65.1877 46.6076L56.5727 46.2756L47.1088 45.8614C43.2328 45.568 40.88 45.4214 40.0509 45.4214H33.2529L26.6909 45.8614H19.0743L12.4382 45.4214L7.9737 45.0538L1.28672 44.6415" stroke="black"/>
        <path id="Stroke 31" d="M116.84 38.0531L106.387 43.5491L99.4035 45.9319C93.1391 47.1966 89.8194 47.8436 89.4444 47.873C88.8817 47.917 76.135 49.0283 73.7043 49.0895C72.0837 49.1303 63.1529 49.11 46.9117 49.0283C33.9389 48.7424 27.1845 48.6165 26.6482 48.6506C26.1122 48.6847 21.4112 48.6675 12.5455 48.5996L1.00005 48.2141" stroke="black"/>
        <path id="Stroke 33" d="M98.7118 38.5405V40.0959L99.1461 40.9797L99.5363 45.8595V46.8298V48.4312" stroke="black"/>
        <path id="Stroke 35" d="M115.078 34.6403C107.723 37.466 102.308 39.1641 98.8318 39.7346C95.3558 40.3048 92.2926 40.8533 89.6419 41.38L84.8894 42.0263L83.1052 42.2043L81.1437 41.7887L65.6397 41.38L57.486 40.8481L48.5607 39.7346C41.8921 39.3408 38.2896 39.164 37.7532 39.2043C37.2168 39.2446 34.0291 39.6141 28.1905 40.3133H20.6795C20.0508 40.3133 17.9097 40.1204 14.2558 39.7346C12.4252 39.5729 11.3818 39.4919 11.126 39.4919H9.15064L4.72304 38.6545L2.67514 38.3456H1.26988" stroke="black"/>
        <path id="Stroke 37" d="M37.2795 5.55029L42.7507 18.7241L43.2085 20.0873L43.4615 20.9657L43.6524 22.2177C43.894 25.7357 44.0946 27.703 44.2545 28.1196C44.4144 28.536 41.8133 32.8459 36.4513 41.0487C36.1256 46.8371 36.057 50.0503 36.2456 50.6888" stroke="black"/>
        <path id="Stroke 39" d="M6.54665 1.9751L6.77063 2.58826L6.65864 4.81778L5.16109 12.6954V14.3152L4.51173 15.3709C3.53793 21.5609 2.83105 26.2291 2.39126 29.3756C1.95162 32.5224 1.84123 36.0276 2.06057 39.8917" stroke="black"/>
        <path id="Stroke 41" d="M9.33411 3.11255L8.79049 3.69695L8.30455 4.02279V6.0061L7.97771 8.88453L7.37802 11.6612L6.56459 15.0012L6.18055 16.0088L5.56356 16.8324L4.83505 19.319L3.89442 23.7379L2.90155 29.5227L2.51831 34.7822V38.244V39.0706L2.23665 40.0738" stroke="black"/>
        <path id="Stroke 43" d="M32.9214 7.98816C33.8724 10.897 34.4941 13.5593 34.7865 15.9747C35.0787 18.39 35.2997 20.6156 35.4495 22.6514C34.661 25.5901 33.8776 27.8996 33.0989 29.5798C32.3204 31.2601 30.3276 35.4067 27.1204 42.0197L26.8631 43.1528V48.6431V49.8316C26.7873 50.2731 26.8732 50.5691 27.1204 50.7189" stroke="black"/>
        <path id="Stroke 45" d="M27.9505 9.12573C28.2985 9.76343 28.5087 10.5139 28.5808 11.3774C28.6888 12.6726 28.8804 17.099 28.8806 17.2509C28.8806 17.3523 28.8807 19.2591 28.8807 22.9714L28.5808 23.7817L25.2634 29.8853L19.7772 41.8468C19.5303 42.2643 19.4137 42.6945 19.4271 43.1373C19.4406 43.5803 19.4339 44.4719 19.4069 45.8125C19.2724 47.9193 19.2724 49.5036 19.4069 50.5656" stroke="black"/>
        <path id="Stroke 47" d="M13.4998 6.36292L13.0351 6.80609C12.7209 10.0897 12.5529 11.8477 12.5309 12.0803C12.4981 12.429 11.0025 19.9932 10.9039 20.171C10.8382 20.2895 9.96457 20.4377 8.28309 20.616L6.61363 21.4596L4.00305 18.4126H3.61773H2.9046" stroke="black"/>
        <path id="Stroke 49" d="M20.8698 9.57118C19.2982 9.32481 18.3186 9.10786 17.9312 8.92048C17.3501 8.63933 10.9916 5.81404 10.4379 5.39931C10.0686 5.12287 8.7684 4.19817 6.53721 2.62537L5.9686 2.82981H5.09814" stroke="black"/>
        <path id="Stroke 51" d="M47.9465 2.30005L45.7825 2.74745L41.7804 5.1268C36.793 8.22154 34.0269 9.86878 33.482 10.0687C32.6645 10.3683 29.9808 11.3922 28.4026 11.5045C27.3505 11.5792 24.7498 11.7047 20.6007 11.8809L17.5144 10.879C10.3835 7.03829 6.70842 5.00784 6.4894 4.78748C6.27039 4.56695 5.94338 4.56695 5.50856 4.78748" stroke="black"/>
        <path id="Stroke 53" d="M18.3063 8.47571L18.0966 8.85826L17.7184 10.8014L16.6897 14.7053C16.2531 16.0277 15.8433 16.758 15.4605 16.8962C15.0777 17.0345 13.8844 16.4421 11.8809 15.1189C7.99342 11.9382 5.94825 10.1663 5.74542 9.80344C5.54242 9.44055 5.17056 9.32711 4.62982 9.4633" stroke="black"/>
        <path id="Stroke 55" d="M20.055 9.43731L20.2289 17.5563C18.3362 15.9471 17.1346 15.0071 16.6243 14.7369C16.1139 14.4666 13.2598 12.4848 8.06216 8.79148L6.14228 7.17627L5.63823 7.01294H5.01259" stroke="black"/>
        <path id="Stroke 57" d="M54.1949 4.90217C53.9508 4.87991 53.5424 5.05266 52.9699 5.42027C51.5722 5.90764 50.84 6.24128 50.773 6.42167C50.7061 6.6019 50.1102 6.94838 48.9855 7.46095C46.5797 9.05325 45.2587 9.92936 45.0225 10.0891C44.6683 10.329 38.5451 14.9218 35.1525 15.9841C32.8909 16.6925 30.8358 17.1128 28.9874 17.2452C24.2223 17.5494 21.7813 17.7017 21.6643 17.7017H20.1744" stroke="black"/>
        <path id="Stroke 59" d="M20.0687 17.5764C19.7172 18.8819 18.6788 19.687 16.9535 19.9917C14.3653 20.4488 11.6967 20.3585 10.8468 19.9917" stroke="black"/>
        <path id="Stroke 61" d="M15.4995 16.9685C17.4266 20.8919 18.6162 23.1208 19.0684 23.6554C19.5206 24.1903 19.765 24.4947 19.8013 24.5686C20.9213 22.0352 21.5349 19.6211 21.6419 17.3262C21.7489 15.0314 21.5583 12.4604 21.0699 9.61316" stroke="black"/>
        <path id="Stroke 63" d="M60.2832 9.45056C58.9335 9.50273 57.1473 10.6598 54.9247 12.9215L49.9981 17.8713C47.3082 19.9905 45.1382 21.4229 43.4884 22.1684C41.0137 23.2865 36.3123 24.7294 34.7843 24.8295C33.2563 24.9296 29.3935 25.7607 27.3664 25.5714C25.3394 25.3821 20.5289 24.7445 19.5315 24.49" stroke="black"/>
        <path id="Stroke 65" d="M64.1284 12.1385C64.1178 10.88 57.722 14.7283 57.5521 15.7076C57.3825 16.6869 45.1565 27.9442 44.2567 28.1601" stroke="black"/>
        <path id="Stroke 67" d="M44.1013 28.1396C37.9245 29.1529 34.2175 29.6695 32.9808 29.6892C31.7441 29.7089 29.1103 29.7805 25.0794 29.9041L12.4862 29.6892H5.29947C3.14791 29.5208 1.89662 29.2984 1.54558 29.0219" stroke="black"/>
        <path id="Stroke 69" d="M6.61047 21.4767L6.43712 24.5711C4.96697 33.595 4.35382 38.7754 4.59767 40.1124" stroke="black"/>
        <path id="Stroke 71" d="M8.85352 50.5678C8.33233 49.6875 8.05307 49.0062 8.0159 48.5239C7.97889 48.0415 8.06092 45.832 8.26247 41.8954C8.31358 41.1747 8.51065 40.7606 8.85352 40.6532" stroke="black"/>
        <path id="Stroke 73" d="M4.84814 50.5329C4.11371 49.7118 3.73992 48.9423 3.72727 48.2242C3.7082 47.1469 3.46964 41.3138 3.74633 41.265C4.02303 41.2161 3.64459 40.6499 4.56312 40.0033" stroke="black"/>
        <path id="Stroke 75" d="M95.0502 36.4345L93.7921 36.9946L89.2819 37.5364L83.0403 37.2655L74.7194 35.779L67.6569 34.0974L62.1699 32.1986L54.6968 30.1027L44.311 28.3024" stroke="black"/>
        <path id="Stroke 77" d="M94.3568 40.9638L94.89 36.4452C94.89 37.1213 94.4305 37.713 93.5117 38.22C92.5927 38.7271 91.1969 39.1184 89.3241 39.3934L85.5963 39.5645C84.8761 35.0995 84.2144 32.3939 83.6115 31.4478C83.4033 31.1213 83.5585 31.0257 83.6115 30.7703C83.6892 30.3949 84.821 29.6306 85.5963 29.4399" stroke="black"/>
        <path id="Stroke 79" d="M94.5697 39.3784C92.4875 39.8268 90.8882 40.1412 89.772 40.3223C88.0976 40.5937 85.1932 40.5937 84.7622 40.5937C84.4749 40.5937 84.0189 40.5031 83.3942 40.3223H82.2508L74.5275 38.9968L66.4893 37.8993L59.4352 36.8796L51.1457 35.5761L40.3221 34.803L30.3891 35.5761H22.7657L5.06668 35.0788C2.79721 35.032 1.57363 34.94 1.39579 34.803" stroke="black"/>
        <path id="Stroke 81" d="M13.6813 50.6633C13.0265 50.1262 12.6663 49.4301 12.6009 48.5748C12.5356 47.7197 12.6232 45.6616 12.8642 42.4006C12.8807 41.8489 12.9696 41.4867 13.1306 41.3141C13.2916 41.1413 13.6462 40.5919 14.1941 39.6655L15.412 35.3732L17.3817 29.9061L19.588 24.6389L10.9118 24.454H9.76639C7.91348 24.5771 6.75751 24.6389 6.29849 24.6389C5.83947 24.6389 5.03213 24.3501 3.87665 23.7722L3.39039 23.4269H2.87096H2.23042" stroke="black"/>
        <path id="Stroke 83" d="M95.0502 49.2215V47.9919V44.1873L94.362 40.8158" stroke="black"/>
        <path id="Stroke 85" d="M85.6775 39.5968L84.6072 40.683" stroke="black"/>
        <path id="Stroke 87" d="M114.116 36.6579C112.899 33.7739 111.25 31.9645 109.168 31.2299C106.045 30.1278 99.4213 30.8615 95.7988 30.1448C92.1763 29.4282 78.8562 26.0872 77.2689 24.7523C75.6815 23.4174 61.5636 13.3868 60.896 12.545C60.2284 11.7032 52.7898 4.46823 51.2384 3.11255" stroke="black"/>
        <path id="Stroke 89" d="M44.9799 1.32495C45.6017 2.81032 48.6163 7.02332 54.0235 13.9644L56.7493 16.6436L61.4063 20.2467C66.4355 24.7787 69.2029 27.1641 69.7089 27.4025C70.4677 27.76 79.9583 32.0926 80.2139 32.5002C80.3842 32.7719 80.5402 33.3536 80.6818 34.2451C80.8498 35.8584 80.9419 36.7441 80.9579 36.9022C80.9739 37.0603 80.9819 38.6539 80.9819 41.6835L81.4153 42.6147L81.6081 43.4633L81.8481 45.9742L82.2329 50.3173" stroke="black"/>
        <path id="Stroke 91" d="M4.12776 12.5385H5.09755C8.77485 17.3267 10.7136 19.8173 10.9138 20.0098C11.214 20.2986 12.5191 22.9699 12.609 24.3618C12.6688 25.2897 12.6987 27.0496 12.6987 29.641C11.7086 35.7213 11.1137 38.9733 10.9138 39.3972C10.714 39.8212 10.4877 40.2695 10.2352 40.7426" stroke="black"/>
        <path id="Stroke 93" d="M3.70404 14.0011L5.05339 14.4084L9.33503 20.4043C10.1924 21.9449 10.7268 23.2236 10.9388 24.2404C11.1508 25.2573 11.2568 27.0571 11.2568 29.6399L10.185 39.3213H8.92295C9.70129 30.7648 9.96469 25.8046 9.71282 24.4403C9.33503 22.3941 6.6812 17.4984 4.30838 15.4636" stroke="black"/>
        <path id="Stroke 95" d="M3.3861 15.1388L4.36743 15.5243" stroke="black"/>
        <path id="Stroke 97" d="M117 39.0282L112.149 41.8345L106.757 44.3918L99.6696 46.7663L95.0179 47.8881L89.7899 48.7819L85.7599 49.3127L82.2496 49.5786C76.9661 49.9431 74.1927 50.1173 73.9293 50.101C73.6658 50.0846 70.7006 50.0929 65.0337 50.1254L36.1064 49.8186L19.2283 49.5786H1.7488" stroke="black"/>
        <path id="Stroke 99" d="M1.70392 41.1407V48.1272C1.77906 48.8457 1.93688 49.2895 2.1772 49.4588C2.53785 49.7127 3.24345 50.5009 4.20715 50.5118" stroke="black"/>
      </g>
    </svg>
  );
};
