import { css } from "@emotion/css";
import React from 'react';
import { useRecoilValue, useRecoilState } from "recoil";
import { Text } from "#/components/text";
import { useWindowSize } from "#/hooks/useWindowSize";
import { Spacer } from "#/components/spacer";
import { motion, useTransform, useMotionValue } from 'framer-motion';
import { Loader } from "#/components/loader";
import { FitVis } from "#/components/ftiFis";
import { configState, numOfScannedShoesState, shoesState } from "#/services/state";
import { ShoePlaceholderSVG } from "./shoeImgPlaceholder";


const PADDING = 24;
const HEIGHT = 96;
const HEIGHT_EXPANDED = 234;

interface ShoeItemProps {
  selected: boolean;
  shoe: IShoe | Record<string, never>;
  onSelected: (id: string) => void;
  onDelete: (id: string) => void;
}

const normalizeShoeWidth = (widthLabel: string): string =>
  widthLabel?.startsWith('1') ? widthLabel.slice(1) : widthLabel;

const ShoeItemOld: React.FC<ShoeItemProps> = (props) => {
  const { colors, borderRadius, boxShadow } = useRecoilValue(configState);

  if (!props.shoe.id) {
    return (
      <Loader
        size={HEIGHT}
      />
    );
  }

  return (
      <motion.div
        onClick={() => props.onSelected(props.shoe.id)}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        className={css`
          height: ${HEIGHT_EXPANDED}px;
          position: relative;
          width: 100%;
          margin-bottom: ${PADDING}px;
        `}
      >
        <motion.div
          className={css`
            height: ${HEIGHT_EXPANDED};
            position: absolute;
            background: ${colors.getSurface()};
            box-shadow: ${boxShadow};
            border-radius: 0 ${borderRadius}px ${borderRadius}px ${borderRadius}px;
            right: 0;
            left: ${HEIGHT / 2}px;
            display: flex;
            padding-left: ${HEIGHT / 2}px;
            flex-direction: column;
            overflow: hidden;
          `}
        >
          <div
            className={css`
              width: 100%;
              height: ${HEIGHT}px;
              padding: 18px ${PADDING}px;
              padding-right: 0;
              box-sizing: border-box;
            `}
          >
            <Text xs wide>{props.shoe.brand}</Text>
            <Spacer />
            <Text xs thin wide>{props.shoe.style}</Text>
            <Spacer />
            <Text sm bold>RECOMMENDED SIZE: </Text>
            <div
              className={css`
                display: inline;
                margin-left: 8px;
              `}
            >
              <Text sm extrabold wider>{props.shoe.length} {normalizeShoeWidth(props.shoe.width)}</Text>
            </div>
          </div>
          <motion.div
            className={css`
              width: 100%;
              height: ${HEIGHT_EXPANDED - HEIGHT}px;       
              display: flex;
              align-items: center;
            `}
          >
            <FitVis
              fit={props.shoe.fit_zone?.data || []}
              id={props.shoe?.id}
            />
          </motion.div>
        </motion.div>
        <motion.div
          className={css`
            position: absolute;
            width: ${HEIGHT}px;
            height: ${HEIGHT}px;
            border-radius: 50%;
            background: white;
            box-shadow: ${boxShadow};
            background-image: url(${props.shoe.image_url});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 75%;
            overflow: hidden;
          `}
        />
      </motion.div>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}



const ShoeItem: React.FC<ShoeItemProps> = (props) => {
  const { colors, borderRadius, boxShadow } = useRecoilValue(configState);
  // const [ imgLoaded, setImgLoaded ] = React.useState(false);

  // React.useEffect(
  //   () => {
  //     if (!props.shoe.image_url) { return; }
  //     const shoeImg = new Image();
  //     shoeImg.onload = () => setImgLoaded(true);
  //     shoeImg.onerror = () => setImgLoaded(false);
  //     shoeImg.src = props.shoe.image_url;
  //   },
  //   [ props.shoe.image_url ],
  // );
  if (props.ghost) {
    return (
      <motion.div
        animate={{ opacity: [0.6, 1, 0.6] }}
        transition={{ duration: 1, repeat: Infinity }}
        className={css`
          position: relative;
          display: grid;
          grid-template-rows: 160px 70px 50px;
        `}
      >
        <div
          className={css`
            background: #f1efef;
            // box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            background-size: 85%;
            overflow: hidden;
            border-radius: 24px;
          `}
        />
        <div
          className={css`
            overflow: hidden;
            margin-left: 16px;
            margin-top: 10px;
          `}
        >
          <div
            className={css`
              background: #f1efef;
              width: 100px;
              border-radius: 12px;
              height: 14px;
              margin: 4px;
            `}
          />
          <div
            className={css`
              background: #f1efef;
              width: 100px;
              border-radius: 12px;
              height: 14px;
              margin: 4px;
            `}
          />
          <div
            className={css`
              background: #f1efef;
              width: 100px;
              border-radius: 12px;
              height: 14px;
              margin: 4px;
            `}
          />
        </div>
        <div
          className={css`
            overflow: hidden;
            margin-left: 7px;
          `}
        >
          <div
            className={css`
              background: #f1efef;
              width: 40px;
              border-radius: 50%;
              height: 40px;
              margin: 4px;
            `}
          />
        </div>
      </motion.div>
    );
  }

  return (
    <div
      className={css`
        position: relative;
        display: grid;
        grid-template-rows: 160px 70px 50px;
        // overflow: hidden;
        // height: 320px;
      `}
    >
      <motion.div
        whileTap={{ scale: 0.9 }}
        className={css`
          background: ${colors.getSurface()};
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          background-image: url(${props.shoe.image_url});
          background-repeat: no-repeat;
          background-position: center;
          background-size: 85%;
          overflow: hidden;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {
          !props.shoe.image_url && <ShoePlaceholderSVG />
        }
      </motion.div>
      <div
        className={css`
          overflow: hidden;
          margin-left: 16px;
          margin-top: 10px;
        `}
      >
        <div>
          <Text sm bold wide>{props.shoe.brand}</Text>
        </div>
        <div>
          <Text sm bold wide>{props.shoe.style}</Text>
        </div>
        <div
          className={css`
            opacity: .5;
          `}
        >
          {
            props.shoe.categories.map((c, i) =>
              <Text sm wide>{capitalizeFirstLetter(props.shoe.categories[i])}</Text>
            )
          }
        </div>
      </div>
      <div
        className={css`
          overflow: hidden;
          margin-left: 7px;
        `}
      >
        <FitVis
          small
          fit={props.shoe.fit_zone?.data || []}
          id={props.shoe?.id}
        />
      </div>
    </div>
  );
};



interface ShoeFeedProps {
  headerHeight: number;
  selectedShoeId: string;
  onSelected: (id: string) => void;
  onShoeContainerRendered: (ref: HTMLDivElement) => void;
}

export const ShoeFeed: React.FC<ShoeFeedProps> = (props) => {
  const windowSize = useWindowSize();
  const scrollPos = useMotionValue(0);
  const feedLabelOpacity = useTransform(scrollPos, [0, 50], [1, 0]);
  const shoeContainer = React.useRef<HTMLDivElement>(null);
  const [shoes, setShoes] = useRecoilState<(IShoe | Record<string, never>)[]>(shoesState);
  const numOfScannedShoes = useRecoilValue(numOfScannedShoesState);
  const [selectedShoe, setSelectedShoe] = React.useState(null);

  React.useEffect(
    () => {
      if (shoeContainer.current) {
        props.onShoeContainerRendered(shoeContainer.current);
        shoeContainer.current.addEventListener(
          'scroll',
          () => {
            scrollPos.set(shoeContainer.current.scrollTop); 
          },
        );
      }
    },
    [shoeContainer.current],
  );
  
  React.useEffect(
    () => {
      // setShoes((JSON.parse(localStorage.getItem('shoes')) || []) as (IShoe | Record<string, never>)[]);
    },
    [],
  );

  // React.useEffect(
  //   () => {
  //     localStorage.setItem('shoes', JSON.stringify(shoes));
  //   },
  //   [shoes],
  // );

  const onDeleteShoe =  (id: string) => {
    setShoes((oldShoes) => oldShoes.filter(shoe => shoe.id !== id));
  };
  return (
    <div
      className={css`
        overflow: scroll;
        height: 100%;
        padding: 24px;
      `}
    >
      <div
        className={css`
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px , 1fr));
          gap: 24px;
          margin: 90px 0;
        `}
      >
        {
          props.loading && Array(8).fill(null).map(a => (
            <ShoeItem
              ghost
            />
          ))
        }
        {
          props.filteredShoes.map(shoe =>
            <div
              onClick={() => setSelectedShoe(shoe.id)}
            >
              <ShoeItem
                selected={shoe.id === props.selectedShoeId}
                key={shoe.id}
                shoe={shoe}
                onSelected={props.onSelected}
                onDelete={(id) => onDeleteShoe(id)}
              />
            </div>
          )
        }
      </div>
      {
        selectedShoe &&
        <ShoeModal
          onClose={() => setSelectedShoe(null)}
          shoe={shoes.find(s => s.id === selectedShoe)}
        />
      }
    </div>
  );
};

const AnimateIn = (props) => {
  return (
    <motion.div
      animate={{ y: 0, opacity: 1, scale: 1 }}
      initial={{ y: 20, opacity: 0.7, scale: 0.9 }}
    >
      { props.children }
    </motion.div>
  );
}

const ShoeModal = ({ shoe, onClose }) => {
  const windowSize = useWindowSize();
  return (
    <motion.div
      className={css`
        position: absolute;
        inset: 0;
        backdrop-filter: blur(4px);
        background: rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
      `}
      onClick={onClose}
    >
      <AnimateIn>
        <div
          onClick={e => e.stopPropagation()}
          className={css`
            position: relative;
            background: white;
            height: 560px;
            max-width: 340px;
            min-width: 230px;
            box-sizing: border-box;
            padding: 24px 24px;
            border-radius: 24px;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            padding: 10px 24px;
            grid-template-rows: 1fr 0.85fr;
            display: grid;
            z-index: 2;
          `}
        >
          <div
            className={css`
              grid-template-rows: 1fr 0.25fr;
              display: grid;
              padding-bottom: 10px;
              box-sizing: border-box;
            `}
          >
            <div
              onClick={onClose}
              className={css`
                height: 28px;
                width: 28px;
                background: white;
                border-radius: 50%;
                box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 16px;
                top: 16px;
              `}
            >
              <CloseIcon
                color="black"
                size="16"
              />
            </div>
            <motion.div
              whileTap={{scale: 1.2}}
              className={css`
                background-image: url(${shoe.image_url});
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
              `}
            />
            <div
              className={css`
                display: grid;
                align-items: center;
              `}  
            >
              <div>
                <Text>{shoe.brand}</Text>
              </div>
              <div>
                <Text bold>{shoe.style}</Text>
              </div>
            </div>
          </div>
          <div
            className={css`
              grid-template-rows: 0.25fr 1fr;
              display: grid;
              align-items: center;
              padding-top: 10px;
              border-top: 2px solid rgba(0,0,0,0.05);
            `}
          >
            <div
              className={css`
                display: grid;
                align-items: center;
              `}  
            >
              <div>
                <Text bold>Your Size: </Text>
                <div
                  className={css`
                    display: inline;
                    margin-left: 4px;
                  `}
                >
                  <Text extrabold>{shoe.length} {normalizeShoeWidth(shoe.width)}</Text>
                </div>
              </div>
            </div>
            <div
              className={css`
                overflow: hidden;
                justify-self: center;
              `}  
            >
              <FitVis
                fit={shoe.fit_zone?.data || []}
                id={shoe?.id}
              />
            </div>
          </div>
        </div>
      </AnimateIn>
    </motion.div>
  )
};

export const CloseIcon: React.FC<{
  thin?: boolean;
  size?: number;
  color?: string;
}> = props => (
  <svg
    className="icon-close-x"
    id="icon-close-x"
    viewBox="0 0 40 40"
    width={ props.size || 28 }
    height={ props.size || 28 }
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
      strokeWidth={ props.thin ? '1.5px' : '3px' }
      stroke={ props.color ? props.color : null}
    >
      <path d="M10,10 L30,30" id="Line" />
      <path d="M10,30 L30,10" id="Line" />
    </g>
  </svg>
);
