import React from 'react';
import { css } from "@emotion/css";
import { configState } from '#/services/state';
import { useRecoilValue } from 'recoil';

interface Props {
  hairline?: boolean;
  thin?: boolean;
  bold?: boolean;
  extrabold?: boolean;
  xxl?: boolean;
  xl?: boolean;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  xs?: boolean;
  xxs?: boolean;
  italic?: boolean;
  tight?: boolean;
  wide?: boolean;
  wider?: boolean;
  lh?: number;
  invert?: boolean;
  dark?: boolean;
  light?: boolean;
  color?: string;
};

const WEIGHTS = {
  hairline: 100,
  thin: 300,
  normal: 500,
  bold: 700,
  extrabold: 900,
};

const SPACINGS = {
  tight: -0.025,
  normal: 0,
  wide: 0.025,
  wider: 0.05,
};

export const Text: React.FC<Props> = ({
  hairline,
  thin,
  bold,
  extrabold,
  italic,
  children,
  xxl,
  xl,
  lg,
  md,
  sm,
  xs,
  xxs,
  tight,
  wide,
  wider,
  lh,
  light,
  dark,
  invert,
  color,
}) => {
  const { colors, fontFamily, fontSizes, baseFontSize } = useRecoilValue(configState);
  const weight: number =
    hairline && WEIGHTS.hairline ||
    thin && WEIGHTS.thin ||
    bold && WEIGHTS.bold ||
    extrabold && WEIGHTS.extrabold ||
    WEIGHTS.normal;

  const letterSpacing: number = 
    tight && SPACINGS.tight ||
    wide && SPACINGS.wide ||
    wider && SPACINGS.wider ||
    SPACINGS.normal;

  const fontSize: string =
    xxl && fontSizes.xxl ||
    xl && fontSizes.xl ||
    lg && fontSizes.lg ||
    md && fontSizes.md ||
    sm && fontSizes.sm ||
    xs && fontSizes.xs ||
    xxs && fontSizes.xxs ||
    fontSizes.md;

  const fontStyle: string = italic && 'italic' || 'normal';

  const lineHeight: string = lh && `${lh}px` || 'normal';

  const colorMode: TextColor =
    invert && 'invert' ||
    dark && 'dark' ||
    light && 'light' ||
    null;

  return (
    <div
      className={css`
        font-family: ${fontFamily};
        color: ${color || colors.getText(colorMode)};
        font-weight: ${weight};
        letter-spacing: ${letterSpacing * baseFontSize}px;
        font-size: ${fontSize};
        line-height: ${lineHeight};
        font-style: ${fontStyle};
        display: inline;
      `}
    >
      { children }
    </div>
  );
};

Text.defaultProps = {
  hairline: false,
  thin: false,
  bold: false,
  extrabold: false,
  xxl: false,
  xl: false,
  lg: false,
  md: false,
  sm: false,
  xs: false,
  xxs: false,
  italic: false,
  tight: false,
  wide: false,
  wider: false,
  lh: 0,
  invert: false,
  dark: false,
  light: false,
  color: '',
};
