export const getQueryProp = (prop: string): string => {
  const props = window.location.search.substring(1).split('&');
  for (let i = 0; i < props.length; i = +1) {
    const pair = props[i].split('=');
    if (decodeURIComponent(pair[0]) === prop) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
};
