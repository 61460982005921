import React from 'react';

export const UALogo: React.FC = () => {
  return (
    <svg viewBox="0 0 302 177.11797" version="1.1">
        <g transform="matrix(5.3483 0 0 -5.3483 221.15 66.479)">
        <path d="m0 0c4.553 2.003 7.526 5.011 7.688 8.348 0 0-1.202 0.926-5.083 2.354-3.408 1.254-6.008 1.541-6.008 1.541l0.012-7.435c0-1.111-0.446-2.245-1.288-3.249-2.576 0.622-5.423 0.971-8.409 0.972h-0.002c-2.988-0.001-5.838-0.351-8.417-0.974-0.842 1.004-1.289 2.139-1.289 3.251l0.011 7.435s-2.598-0.287-6.008-1.541c-3.881-1.428-5.081-2.354-5.081-2.354 0.161-3.338 3.135-6.347 7.693-8.35-4.554-2.003-7.527-5.011-7.688-8.347 0 0 1.201-0.927 5.082-2.354 3.409-1.254 6.008-1.541 6.008-1.541l-0.012 7.435c0 1.111 0.446 2.246 1.289 3.249 2.576-0.623 5.422-0.971 8.408-0.971h0.001c2.989 0 5.84 0.349 8.418 0.974 0.842-1.004 1.288-2.141 1.288-3.252l-0.01-7.435s2.599 0.287 6.008 1.541c3.881 1.427 5.082 2.354 5.082 2.354-0.162 3.338-3.136 6.347-7.693 8.349m-13.092-1.673h-0.003l-0.085 0.002c-2.696 0-4.894 0.66-6.506 1.669 1.612 1.011 3.811 1.672 6.512 1.672l0.083 0.001h0.004l0.085-0.001c2.697 0 4.894-0.66 6.506-1.669-1.612-1.01-3.811-1.672-6.511-1.672"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 8.1081 176.02)">
        <path d="m0 0c-1.306 0-1.329 0.996-1.329 1.38v1.812c0 0.11-0.009 0.256 0.224 0.256h0.675c0.219 0 0.205-0.156 0.205-0.256v-1.812c0-0.129 0.024-0.475 0.499-0.475h1.132c0.466 0 0.493 0.346 0.493 0.475v1.812c0 0.1-0.013 0.256 0.205 0.256h0.68c0.247 0 0.225-0.156 0.225-0.256v-1.812c0-0.384-0.023-1.38-1.334-1.38"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 41.561 174.85)">
        <path d="m0 0c-0.461 0.621-1.119 1.403-1.79 2.229v-2.206c0-0.096 0.024-0.242-0.205-0.242h-0.63c-0.228 0-0.201 0.146-0.201 0.242v2.95c0 0.1-0.01 0.256 0.201 0.256h1.228c0.347 0 0.841-0.699 1.165-1.16 0.25-0.352 0.712-0.881 1.022-1.278v2.182c0 0.1-0.014 0.256 0.215 0.256h0.684c0.22 0 0.206-0.156 0.206-0.256v-3.192h-1.26c-0.27 0-0.389-0.018-0.635 0.219"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 69.324 171.17)">
        <path d="m0 0c0.552 0 0.512 0.53 0.512 0.772 0 0.813-0.229 0.845-0.594 0.845h-1.735v-1.617h1.817zm-2.882-0.908v3.443h3.043c0.186 0 1.432 0.022 1.432-1.287 0-1.339 0.146-2.156-1.405-2.156h-1.64l-0.356 0.721v-0.721"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 84.219 157.73)">
        <path d="m0 0h2.803c0.238 0 0.206-0.155 0.206-0.443 0-0.268 0.036-0.419-0.206-0.419h-2.215c-0.059 0-0.259 0.032-0.259-0.178s-0.041-0.293 0.182-0.293h1.959s0.283-0.539 0.315-0.63c0.046-0.082 0.041-0.16-0.132-0.16h-2.028l-0.315 0.612v-0.904c0-0.21 0.188-0.169 0.242-0.169h2.301c0.229 0 0.21-0.159 0.21-0.419 0-0.28 0.019-0.434-0.21-0.434h-2.913c-0.242 0-0.716 0.073-0.716 0.83v1.9c0 0.196 0.105 0.707 0.776 0.707"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 117.42 162.47)">
        <path d="m0 0h-1.365c-0.227 0-0.219-0.078-0.219-0.147v-0.616h1.406c0.681 0 0.681 0.17 0.681 0.348 0 0.356-0.027 0.415-0.503 0.415m-2.442 0.909h3.068c0.411 0 1.008 0.004 1.008-1.105 0-0.726-0.154-0.762-0.483-0.986 0.553-0.096 0.48-0.803 0.48-1.169 0-0.187-0.068-0.183-0.124-0.183h-0.899c-0.188 0-0.142 0.297-0.142 0.452 0 0.425-0.237 0.406-0.329 0.406h-1.324c-0.127 0.22-0.374 0.713-0.374 0.713v-1.429c0-0.124-0.124-0.142-0.169-0.142h-0.903c0.049 0-0.093-0.009-0.093 0.115v3.031c0 0.215 0.151 0.297 0.284 0.297"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 152.46 162.83)">
        <path d="m0 0-0.566-1.032 0.05-0.05h1.055l0.055 0.05c-0.037 0.078-0.493 0.945-0.539 1.037l-0.055-0.005m0.365 0.953c0.224 0 0.442-0.1 0.68-0.561 0.161-0.328 1.275-2.341 1.507-2.762v-0.114h-1.16l-0.334 0.603h-1.41s-0.237 0.543-0.305 0.693c-0.087-0.141-0.553-1.027-0.714-1.296h-1.163v0.073c0.237 0.447 1.858 3.364 1.858 3.364"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 182.38 162.47)">
        <path d="m0 0h-1.365c-0.228 0-0.219-0.078-0.219-0.147v-0.616h1.406c0.681 0 0.681 0.17 0.681 0.348 0 0.356-0.027 0.415-0.503 0.415m-2.442 0.909h3.068c0.411 0 1.009 0.004 1.009-1.105 0-0.726-0.154-0.762-0.484-0.986 0.552-0.096 0.479-0.803 0.479-1.169 0-0.187-0.068-0.183-0.123-0.183h-0.899c-0.188 0-0.142 0.297-0.142 0.452 0 0.425-0.237 0.406-0.329 0.406h-1.324c-0.128 0.22-0.375 0.713-0.375 0.713v-1.429c0-0.124-0.122-0.142-0.168-0.142h-0.904c0.05 0-0.091-0.009-0.091 0.115v3.031c0 0.215 0.151 0.297 0.283 0.297"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 205.46 176.02)">
        <path d="m0 0c-0.229 0.429-0.864 1.612-1.293 2.387v-2.145c0-0.096 0.013-0.242-0.204-0.242h-0.622c-0.233 0-0.215 0.146-0.215 0.242v2.95c0 0.1-0.013 0.251 0.215 0.251h1.073c0.16 0 0.387 0.037 0.644-0.475 0.192-0.41 0.599-1.261 0.877-1.712 0.287 0.451 0.703 1.302 0.895 1.712 0.251 0.512 0.474 0.475 0.652 0.475h1.068c0.22 0 0.197-0.151 0.197-0.251v-2.95c0-0.096 0.031-0.242-0.197-0.242h-0.625c-0.228 0-0.21 0.146-0.21 0.242v2.145c-0.433-0.775-1.064-1.958-1.302-2.387"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 241.35 171.33)">
        <path d="m0 0c0.517 0 0.498 0.533 0.498 0.89 0 0.32 0.073 0.822-0.598 0.822h-1.138c-0.666 0-0.588-0.502-0.588-0.822 0-0.357-0.032-0.89 0.484-0.89h1.342zm-2.045-0.886c-1.018 0-0.973 1.092-0.973 1.798 0 0.649-0.073 1.662 1.201 1.672h2.261c1.278 0 1.195-1.027 1.195-1.672 0-0.706 0.051-1.798-0.982-1.798"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 259.22 176.02)">
        <path d="m0 0c-1.307 0-1.329 0.996-1.329 1.38v1.812c0 0.11-0.009 0.256 0.224 0.256h0.676c0.218 0 0.205-0.156 0.205-0.256v-1.812c0-0.129 0.023-0.475 0.498-0.475h1.132c0.466 0 0.493 0.346 0.493 0.475v1.812c0 0.1-0.014 0.256 0.205 0.256h0.68c0.247 0 0.225-0.156 0.225-0.256v-1.812c0-0.384-0.023-1.38-1.334-1.38"/>
        </g>
        <g transform="matrix(5.3483 0 0 -5.3483 292.25 162.47)">
        <path d="m0 0h-1.365c-0.228 0-0.219-0.078-0.219-0.147v-0.616h1.406c0.681 0 0.681 0.17 0.681 0.348 0 0.356-0.027 0.415-0.503 0.415m-2.443 0.909h3.069c0.411 0 1.01 0.004 1.01-1.105 0-0.726-0.156-0.762-0.485-0.986 0.553-0.096 0.48-0.803 0.48-1.169 0-0.187-0.068-0.183-0.124-0.183h-0.899c-0.187 0-0.142 0.297-0.142 0.452 0 0.425-0.237 0.406-0.328 0.406h-1.325c-0.127 0.22-0.374 0.713-0.374 0.713v-1.429c0-0.124-0.124-0.142-0.169-0.142h-0.903c0.049 0-0.092-0.009-0.092 0.115v3.031c0 0.215 0.151 0.297 0.282 0.297"/>
        </g>
    </svg>
  );
};
