import { css } from "@emotion/css";
import React from 'react';
import { useWindowSize } from "#/hooks/useWindowSize";
import { ShoeFeed } from "#/components/shoeFeed";
import { getBestFit, getRecommendation } from '#/services/recommendation';
import { ScanButton } from "#/components/scanButton";
import { BarcodeScanner } from "#/components/barcodeScanner";
import { Onboarding } from "#/components/onboarding";
import { HeaderMenu } from "#/components/headerMenu";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { configState, loadingShoeState, measurementsState, shoesState } from "#/services/state";
import { parseMeasurements, parseExternalData } from "./services/measurements";
import { getQueryProp } from "./services/helpers";
import { Text } from "./components/text";
import { motion, useMotionValue, useTransform } from "framer-motion";

// import { UserProfile } from "#/components/userProfile";
// import { Tooltips } from "#/components/tooltips";

const HEADER_HEIGHT = 0;

export const App: React.FC = () => {
  const { colors } = useRecoilValue(configState);
  const windowSize = useWindowSize();
  const [shoes, setShoes] = useRecoilState<(IShoe | Record<string, never>)[]>(shoesState);
  const [measurements, setMeasurements] = useRecoilState(measurementsState);
  const loadingShoe = useRecoilValue(loadingShoeState);
  const [isBarcodeReaderActive, setIsBarcodeReaderActive] = React.useState(false);
  const [isProfileActive, setIsProfileActive] = React.useState(false);
  const [selectedShoeId, setSelectedShoeId] = React.useState('');
  const [shoeContainerDivElem, setShoeContainerDivElem] = React.useState<HTMLDivElement>(null);
  const [categories, setCategories] = React.useState([]);
  const [filterModalVisibility, setFilterModalVisibility] = React.useState(false);

  // React.useEffect(
  //   () => {
  //     setMeasurements(parseMeasurements() || JSON.parse(localStorage.getItem('measurements')) as Measurements);
  //   },
  //   [],
  // );

  React.useEffect(
    async () => {
      // const externalData = parseExternalData();
      const shoes = await getBestFit(
        getQueryProp('tenant'),
        getQueryProp('scan_id'),
      );
      setShoes(shoes);
    },
    [],
  );

  React.useEffect(
    () => {
      const cats = {};
      shoes
        .flatMap(s=> s.categories)
        .filter((val, i, self) => self.indexOf(val) === i)
        .forEach(c => cats[c] = true);
      setCategories(cats);
    },
    [shoes],
  );

  // const fetchRecommendation = async (barcode: string) => {
  //   setShoes((oldShoes) => ([
  //     {},
  //     ...oldShoes,
  //   ]));
  //   setSelectedShoeId(null);
  //   const scannedShoe = await getRecommendation(barcode, measurements);
  //   setSelectedShoeId(scannedShoe.id);
  //   setShoes((oldShoes) => [
  //     scannedShoe,
  //     ...oldShoes.filter(s => s.id),
  //   ]);
  // };

  const toggleCategory = (cat) => {
    const updatedCategories = {
      ...categories,
      [cat]: !categories[cat],
    };
    setCategories(updatedCategories);
  };

  const shoeFilter = (_shoes) => {
    return _shoes.filter(s => {
      let visible = false;
      s.categories.forEach(c => {
        if (visible) {
          return;
        }
        const enabledCategories = Object.keys(categories).filter(cc => categories[cc]);
        if (enabledCategories.includes(c)) {
          visible = true;
        }
      })
      return visible;
    })
  }

  return (
    <div
      className={css`
        background: white;
        height: ${windowSize.height}px;
        width:  ${windowSize.width}px;
        overflow: hidden;
        position: absolute;
      `}
    >
      {/* <Onboarding /> */}
     
      <ShoeFeed
        loading={!shoes.length}
        onShoeContainerRendered={setShoeContainerDivElem}
        selectedShoeId={selectedShoeId}
        onSelected={setSelectedShoeId}
        headerHeight={HEADER_HEIGHT}
        filteredShoes={shoeFilter(shoes)}
      />
       <HeaderMenu
        shoeContainerDivElem={shoeContainerDivElem}
        height={76}
        onProfileTap={() => setIsProfileActive(true)}
        onFilterTap={() => setFilterModalVisibility(true)}
      />
      {/* <Tooltips /> */}
      {/* <ScanButton
        disabled={loadingShoe}
        onTap={() => setIsBarcodeReaderActive(true)}
      /> */}
      {
        shoes.length &&
        <FloatingButtons
          onFilterTap={() => setFilterModalVisibility(true)}
        />
      }
      {
        filterModalVisibility &&
        <FilterModal
          onClose={() => setFilterModalVisibility(false)}
          categories={categories}
          toggleCategory={toggleCategory}
        />
      }
      {/* <BarcodeScanner
        isActive={isBarcodeReaderActive}
        onClose={() => setIsBarcodeReaderActive(false)}
        onBarcodeReceived={fetchRecommendation}
      /> */}
      {/* <UserProfile
        isActive={isProfileActive}
        onClose={() => setIsProfileActive(false)}
      /> */}
     
    </div>
  );
};


const FloatingButtons = (props) => {
  return (
    <div>
      <motion.div
        whileTap={{ scale: 0.9 }}
        onClick={props.onFilterTap}
        className={css`
          height: 64px;
          width: 64px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: black;
          border-radius: 50%;
          margin: 24px;
          box-shadow: 0px 2px 20px rgb(0 0 0 / 25%);
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <FilterIcon />
      </motion.div>
    </div>
  );
}

const AnimateIn = (props) => {
  return (
    <motion.div
      animate={{ y: 0, opacity: 1, scale: 1 }}
      initial={{ y: 20, opacity: 0.7, scale: 0.9 }}
    >
      { props.children }
    </motion.div>
  );
}

const FilterModal = (props) => {
  return (
    <motion.div
      className={css`
        position: absolute;
        inset: 0;
        backdrop-filter: blur(4px);
        background: rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      onClick={props.onClose}
    >
     
      {/* <div
        onClick={props.onClose}
        className={css`
          backdrop-filter: blur(4px);
          background: rgba(0,0,0,0.1);
          position: absolute;
          inset: 0;
        `}
      >

      </div> */}
      <AnimateIn>
        <div
          onClick={e => e.stopPropagation()}
          className={css`
            position: relative;
            background: white;
            height: 560px;
            max-width: 340px;
            min-width: 230px;
            box-sizing: border-box;
            padding: 24px 24px;
            border-radius: 24px;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          `}
        >
          <div
            onClick={props.onClose}
            className={css`
              height: 28px;
              width: 28px;
              background: white;
              border-radius: 50%;
              box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 16px;
              top: 16px;
            `}
          >
            <CloseIcon
              color="black"
              size="16"
            />
          </div>
          <div
            className={css`
              margin-top: 48px;
            `}
          >
            <Text>CATEGORIES:</Text>
          </div>
          <div
            className={css`
              display: flex;
              flex-wrap: wrap;
              margin-top: 24px;
              justify-content: center;
            `}  
          >
            {
              Object.keys(props.categories).map(cat => (
                <div
                  onClick={() => props.toggleCategory(cat)}
                >
                  <Tag enabled={props.categories[cat]}>{cat.toUpperCase()}</Tag>
                </div>
              ))
            }
          </div>
        </div>
      </AnimateIn>
    </motion.div>
  );
}

const FilterIcon: React.FC = () => {
  const { colors } = useRecoilValue(configState);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 5H6V7H3V9H6V11H8V9V7V5ZM21 9H9V7H21V9Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18 13H16V15V17V19H18V17H21V15H18V13ZM3 15H15V17H3V15Z" fill="white"/>
    </svg>
  );
};

export const CloseIcon: React.FC<{
  thin?: boolean;
  size?: number;
  color?: string;
}> = props => (
  <svg
    className="icon-close-x"
    id="icon-close-x"
    viewBox="0 0 40 40"
    width={ props.size || 28 }
    height={ props.size || 28 }
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
      strokeWidth={ props.thin ? '1.5px' : '3px' }
      stroke={ props.color ? props.color : null}
    >
      <path d="M10,10 L30,30" id="Line" />
      <path d="M10,30 L30,10" id="Line" />
    </g>
  </svg>
);

const Tag = (props) => {
  return (
    <div
      className={css`
        background: ${ props.enabled ? '#333' : 'transparent' };
        width: fit-content;
        padding: 8px;
        border-radius: 16px;
        margin: 4px;
      `}
    >
      <Text dark={props.enabled} wide>{props.children}</Text>
    </div>
  )
}