import { css } from "@emotion/css";
import React from 'react';

interface Props {
  xxl?: boolean;
  xl?: boolean;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  xs?: boolean;
  xxs?: boolean;
};

export const Spacer: React.FC<Props> = ({
  xxl,
  xl,
  lg,
  md,
  sm,
  xs,
  xxs,
}) => {
  const spacing: number =
    xxl && 64 ||
    xl && 32 ||
    lg && 24 ||
    md && 16 ||
    sm && 8 ||
    xs && 4 ||
    xxs && 0 ||
    0;

  return (
    <div
      className={css`
        height: ${spacing}px;
      `}
    />
  );
};

Spacer.defaultProps = {
  xxl: false,
  xl: false,
  lg: false,
  md: false,
  sm: false,
  xs: false,
  xxs: false,
};
