import { css } from "@emotion/css";
import React from 'react';
import { motion, useMotionValue, useTransform } from "framer-motion";
import { Text } from "#/components/text";
import { configState } from "#/services/state";
import { useRecoilValue } from "recoil";
import { getQueryProp } from "#/services/helpers";
import { VolumentalLogo } from "./volumentalLogo";
import { UALogo } from "./UALogo";

const ProfileIcon: React.FC = () => {
  const { colors } = useRecoilValue(configState);
  // const { email } = useRecoilValue(userState);
  
  // if (email) {
  //   return (
  //     <div
  //       className={css`
  //         height: 33px;
  //         width: 33px;
  //         border-radius: 50%;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         background: ${colors.getAccent()};
  //       `}
  //     >
  //       <Text>
  //         {email[0].toUpperCase()}
  //       </Text>
  //     </div>
  //   );
  // }

  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 2C9.272 2 3 8.272 3 16C3 23.728 9.272 30 17 30C24.728 30 31 23.728 31 16C31 8.272 24.728 2 17 2ZM17 6.2C19.324 6.2 21.2 8.076 21.2 10.4C21.2 12.724 19.324 14.6 17 14.6C14.676 14.6 12.8 12.724 12.8 10.4C12.8 8.076 14.676 6.2 17 6.2ZM8.6 21.572C10.406 24.288 13.5 26.08 17 26.08C20.5 26.08 23.594 24.288 25.4 21.572C25.358 18.786 19.786 17.26 17 17.26C14.2 17.26 8.642 18.786 8.6 21.572Z" fill={colors.getText()}/>
    </svg>
  );
};

const FilterIcon: React.FC = () => {
  const { colors } = useRecoilValue(configState);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 5H6V7H3V9H6V11H8V9V7V5ZM21 9H9V7H21V9Z" fill={colors.getText()}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18 13H16V15V17V19H18V17H21V15H18V13ZM3 15H15V17H3V15Z" fill={colors.getText()}/>
    </svg>
  );
};

interface Props {
  height: number;
  onProfileTap: () => void;
  onFilterTap: () => void;
  shoeContainerDivElem: HTMLDivElement;
};

export const HeaderMenu: React.FC<Props> = (props) => {
  const { padding, colors, boxShadow } = useRecoilValue(configState);
  const scrollPos = useMotionValue(0);
  const opacity = useTransform(scrollPos, [20, 50], [0, 1]);
  const yHeader = useTransform(scrollPos, [50, 120], [40, 0]);

  React.useEffect(
    () => {
      if (props.shoeContainerDivElem) {
        props.shoeContainerDivElem.addEventListener(
          'scroll',
          () => {
            scrollPos.set(props.shoeContainerDivElem.scrollTop);
          },
        );
      }
    },
    [props.shoeContainerDivElem],
  );

  return (
    <div
      className={css`
        position: absolute;
        background: rgba(255,255,255,0.8);
        backdrop-filter: blur(6px);
        width: 100%;
        height: ${props.height}px;
        box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.1);
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        className={css`
          width: 60px;
        `}
      >
       {
        getQueryProp('tenant') === 'under_armour' ?
          <UALogo /> :
          <VolumentalLogo />
       } 
      </div>
    </div>
  );
};
