import { v4 as uuidv4 } from 'uuid';

const FIT_ZONES: FitZone[] = [
  '',
  'loose',
  'great',
  'tight',
  'unknown',
];

export const getRecommendation = async (bc: string, measurements: Measurements): Promise<IShoe> => {
  const barcode = (bc && bc[0] === '0') ? bc.slice(1) : bc;
  
  const payload: IRecommendationPayload = {
    tenant: measurements.tenant,
    upcs: [barcode],
    gender_age: measurements.gender_age,
    reconstruction_output: {
      measurements_computed: {
        measurements: {
          left_length: measurements.left_length,
          left_width: measurements.left_width,
          left_instep_height: measurements.left_instep_height,
          left_heel_width: measurements.left_heel_width,
          right_length: measurements.right_length,
          right_instep_height: measurements.right_instep_height,
          right_width: measurements.right_width,
          right_heel_width: measurements.right_heel_width,
        }
      }
    }
  };

  const r = await fetch(
    '/api/recommendation',
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(payload)
    }
  );
  const json = await r.json() as Record<string, unknown>;
  const response = {
    ...json.data[barcode],
    id: uuidv4(),
  } as IShoe;
  if (response.fit_zone && response.fit_zone.img) {
    response.fit_zone.data = response.fit_zone.img
      .slice(-12,-4)
      .split('')
      .filter(i => ['1','2','3','4'].includes(i))
      .map(i => FIT_ZONES[i] as FitZone);
  }
  await new Promise(resolve => setTimeout(resolve, 1000));
  return response;
};

export const getBestFit = async (tenant: string, scan_id: string) => {
  const payload = {
    tenant,
    scan_id,
    count: 100,
  };
  const r = await fetch(
    '/api/bestfit',
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(payload)
    }
  );
  const json = await r.json() as Record<string, unknown>;
  const resp = json.data.shoes
  return resp.map(s => {
    let fitVizData = null;
    if (s.fit_viz_url) {
      fitVizData = s.fit_viz_url
        .slice(-12,-4)
        .split('')
        .filter(i => ['1','2','3','4'].includes(i))
        .map(i => FIT_ZONES[i] as FitZone);
    }
    return {
      brand: s.brand_name,
      fit_zone: {
        img: s.fit_viz_url,
        data: fitVizData,
      },
      gender_age: s.gender,
      image_url: s.fullsize_image,
      length: s.length,
      locale: null,
      style: s.style_name,
      width: s.width,
      id: s.order + 1,
      categories: s.categories,
    }
  })
  return resp;
};

// availability: {store: true}
// brand_name: "Red Wing Workboots"
// categories: (12) ['safety toe / non-metallic toe', 'other safety / electrical hazard', 'what are you stepping in \\ on / waterproof', 'what are you stepping in \\ on / chemical', 'what are you stepping in \\ on / slip resistance', 'what are you stepping in \\ on / oil/gas', 'what are you stepping in \\ on / heat resistance', 'what are you stepping in \\ on / non marking', 'what are you stepping in \\ on / abrasion', 'what are you stepping in \\ on / ladders - 90 degree heel', 'closure method / boa® lacing system', 'height \\ silhouette / 6"']
// fit_viz_url: "https://engage-assets.volumental.com/fitzones/large_fit_a2a2a2a2.svg"
// fullsize_image: "https://storage.googleapis.com/volumental-fitapi-prd-storage-public/sha256/5dfb906b67d6abc456d02bf00747e3058d4a1c903fa07e106d129f3f57fc64ed"
// gender: "female"
// length: "8"
// order: 1
// skus: ['02344B 080']
// style_name: "Tradeswoman 2344"
// width: "B"

// const getFakeRecommendation = async () => await new Promise(resolve => setTimeout(() => {
//   const shoe = getRandomShoe();
//   const response = {
//     ...shoe.data,
//     id: uuidv4(),
//   };
//   if (response.fit_zone?.img) {
//     response.fit_zone.data = response.fit_zone.img
//       .slice(-12,-4)
//       .split('')
//       .filter(i => ['1','2','3','4'].includes(i))
//       .map(i => FIT_ZONES[i]);
//   }
//   return resolve(response);
// }, 1000));