import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { App } from "./app";
import './normalize.css';

// eslint-disable-next-line no-undef
const root = document.getElementById("root");

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  root
);
