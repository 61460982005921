import { atom, selector } from "recoil";

// const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
const isDark = false;

const baseFontSize = 16;
const fontFamily = `'Lato', sans-serif`;
const fontSizes = {
  xxl: `${baseFontSize * 2}px`,
  xl: `${baseFontSize * 1.75}px`,
  lg: `${baseFontSize * 1.25}px`,
  md: `${baseFontSize}px`,
  sm: `${baseFontSize * .75}px`,
  xs: `${baseFontSize * .625}px`,
  xxs: `${baseFontSize * .5}px`,
};

// MAIN DATA STORES

export const measurementsState = atom<Measurements>({
  key: 'measurementsState',
  default: null,
});

export const shoesState = atom<(IShoe | Record<string, never>)[]>({
  key: 'shoesState',
  default: [],
});

export const configState = atom<IConfigState>({
  key: 'configState',
  default: {
    isDark,
    fontFamily,
    fontSizes,
    baseFontSize,
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: 24,
    padding: 24,
    colors: {
      getBG: () => isDark ? '#222222' : '#F2F2F2',
      getText: mode => {
        if (mode === 'dark') {
          return `#F2F2F2`;
        }
        if (mode === 'light') {
          return `#1A1A1A`;
        }
        if (mode === 'invert') {
          return isDark ? `#1A1A1A` : `#F2F2F2`;
        }
        return isDark ? `#F4F4F4` : `#1A1A1A`;
      },
      getSurface: () => isDark ? '#333333' : '#FFFFFF',
      getAccent: () => `#E56A74`,
    },
  },
});

// SELECTORS

export const numOfScannedShoesState = selector({
  key: 'numOfScannedShoesState',
  get: ({ get }) => {
    const shoes = get(shoesState);
    return shoes.length;
  },
});

export const loadingShoeState = selector({
  key: 'loadingShoeState',
  get: ({ get }) => {
    const shoes = get(shoesState);
    return shoes.length && !shoes[0].id;
  },
});
